<fieldset class="border p-3 my-3">
  <legend class="legendFormulario" *ngIf="!trilhasExperiencias && certificados.length">Início</legend>

  <div id="progress-modal-center" class="progess-modal-center">
    <h1>Notificação</h1>
    <strong>
      Olá, Adrian.<br>
      Esperamos que esteja bem.<br>
      Enviamos dois e-mails para o endereço que temos em seu cadastro no dias 03 e 16 de abril.
      Favor entrar em contato através de um dos nossos canais de atendimento:<br>
      Telefone: (11) 4280-8520<br>
      E-mail: conselhodeetica@planejar.org.br
    </strong>
    <br><br><br>
    <button (click)="aceiteModalNotificacao()">Entendi</button>
  </div>

  <div class="dashboard" *ngIf="!trilhasExperiencias && certificados.length">
    <div class="row">
      <div class="col-md-12 d-flex" style="flex-direction: column;">
        <Label *ngIf="!isRenovacao">Trilha de Certificação CFP®</Label>
        <Label *ngIf="isRenovacao">Trilha de Renovação CFP®</Label>
        <Label *ngIf="!dtValidade">Prazo para comprovação dos requisitos obrigatórios {{ dtValidade | date:
          'dd/MM/yyyy'}}</Label>
      </div>
      <div *ngIf="isRenovacao && dtValidadeCertificado!=null" class="col-md-12 d-flex"
        style="display: flex; justify-content: flex-end">
        <Label>Certificado válido até {{ dtValidadeCertificado | date: 'dd/MM/yyyy'}}</Label>
      </div>
    </div>
    <div class="row mt-3 ">
      <div class="col-md-3" *ngFor="let certificado of certificados">
        <div class="row">
          <div class="col-md-12">
            <circle-progress percent="{{certificado.percentualConclusao}}" maxPercent="{{certificado.quantidadeTotal}}"
              [radius]="90" [outerStrokeWidth]="5" responsive="true" [innerStrokeWidth]="1"
              outerStrokeColor=" {{certificado.percentualConclusao != '100.0' ? '#004c6c': '#004c6c'}}"
              innerStrokeColor="{{certificado.percentualConclusao != '100.0' ? '#dbcb25': '#a5cd39'}}"
              backgroundColor="{{certificado.percentualConclusao != '100.0' ? '#dbcb25': '#a5cd39'}}" [animation]="true"
              [animationDuration]="10" [showUnits]="false" space="10" [title]="certificado.subTitulos"
              titleFontSize="20" titleColor="#004c6c" titleFontWeight="bolder" showSubtitle="true"
              [subtitle]="certificado.descricaoTrilhaItem" subtitleColor="#004c6c" [subtitleFontWeight]="20"
              [subtitleFontSize]="14" (click)="detalhes(certificado.idTrilhaItem)"
              style="cursor: pointer;"></circle-progress>
          </div>
          <!-- <div class="col-md-12" align="center" style="padding-left: 37px !important;">
                <button class="btn-sm btn-primary"
                (click)="detalhes(certificado.idTrilhaItem)">
                  Detalhes
                </button>
              </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="dashboard"
    *ngIf="!trilhasExperiencias && (certificados === null || certificados.length === 0) && naoCertificados.length && !isRenovacao">
    <div class="row mt-4">
      <div class="col-md-12" *ngIf="(certificados === null || certificados.length === 0)">
        <Label>Trilha de Associação Não Certificada</Label>
      </div>
      <div class="col-md-12" *ngIf="certificados.length">
        <Label>Solicitação de Associação Não Certificado</Label>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-12">
        <div class="col-md-3" *ngFor="let certificado of naoCertificados">
          <div class="row">
            <div class="col-md-12">
              <circle-progress percent="{{certificado.percentualConclusao}}"
                maxPercent="{{certificado.quantidadeTotal}}" [radius]="90" [outerStrokeWidth]="5" responsive="true"
                [innerStrokeWidth]="1"
                outerStrokeColor=" {{certificado.percentualConclusao != '100.0' ? '#004c6c': '#004c6c'}}"
                innerStrokeColor="{{certificado.percentualConclusao != '100.0' ? '#dbcb25': '#a5cd39'}}"
                backgroundColor="{{certificado.percentualConclusao != '100.0' ? '#dbcb25': '#a5cd39'}}"
                [animation]="true" [animationDuration]="10" [showUnits]="false" space="10"
                [title]="certificado.subTitulos" titleFontSize="20" titleColor="#004c6c" titleFontWeight="bolder"
                showSubtitle="true" [subtitle]="certificado.descricaoTrilhaItem" subtitleColor="#004c6c"
                [subtitleFontWeight]="20" [subtitleFontSize]="14" (click)="detalhes(certificado.idTrilhaItem)"
                style="cursor: pointer;"></circle-progress>
            </div>
            <!-- <div class="col-md-12" align="center" style="padding-left: 37px !important;">
            <button class="btn-sm btn-primary"
                    (click)="detalhes(certificado.idTrilhaItem)">
              Detalhes
            </button>
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-12">
        <a href="javascript:history.go(-1)">
          << Voltar</a>
      </div>
    </div>
  </div>

  <div class="dashboard" *ngIf="trilhasExperiencias">
    <div class="row">
      <div class="col-md-12 d-flex" style="flex-direction: column;"
        *ngIf="isCredenciamentoSupervisor===false && nomeSupervisionado !== null">
        <Label>Supervisionado: {{nomeSupervisionado}}</Label>
      </div>
      <div class="col-md-12 d-flex" style="flex-direction: column;" *ngIf="dataInicioAtividade !== null">
        <Label>Início das Atividades: {{dataInicioAtividade | date: 'dd/MM/yyyy'}}</Label>
      </div>
      <div class="col-md-12 d-flex" style="flex-direction: column;" *ngIf="dataTerminoAtividade !== null">
        <Label>Término das Atividades: {{dataTerminoAtividade | date: 'dd/MM/yyyy'}}</Label>
      </div>

      <div class="col-md-12 d-flex" style="flex-direction: column;"
        *ngIf="isCredenciamentoSupervisor===false && nomeSupervisionado === null">
        <Label>Trilha de Experiência Profissional Supervisionada</Label>
        <div>
          <a *ngIf="existeEtapasEmAndamento" (click)="confirmarDesistenciaSupervisionado();" style="cursor:pointer">-
            Desistir da experiência supervisionada</a><br />
          <a *ngIf="existeSupervisorAceito" (click)="confirmarTrocaSupervisor();" style="cursor:pointer">- Efetuar troca
            de supervisor</a>
        </div>
      </div>
      <div class="col-md-12 d-flex" style="flex-direction: column;"
        *ngIf="isCredenciamentoSupervisor===true && nomeSupervisionado === null">
        <Label>O Supervisor - Experiência Profissional Supervisionada</Label>
      </div>
    </div>
    <div class="row mt-3 ">
      <div class="col-md-3" *ngFor="let trilha of trilhasExperiencias">
        <div class="row">
          <div class="col-md-12">
            <circle-progress
              percent="{{trilha.percentualConclusao}}"
              maxPercent="{{trilha.quantidadeTotal}}"
              [radius]="90"
              [outerStrokeWidth]="5"
              responsive="true"
              [innerStrokeWidth]="1"
               outerStrokeColor="#004c6c"
              innerStrokeColor="{{trilha.percentualConclusao === '100.0' ? '#a5cd39' : (trilha.idSituacao === 2 || trilha.idSituacao === 3) ? '#DCDCDC' : (trilha.idSituacao === 4 || trilha.idSituacao === 5) ? '#ff0000' : '#dbcb25' }}"
              backgroundColor="{{trilha.percentualConclusao === '100.0' ? '#a5cd39' : (trilha.idSituacao === 2 || trilha.idSituacao === 3) ? '#DCDCDC' : (trilha.idSituacao === 4 || trilha.idSituacao === 5) ? '#ff0000' : '#dbcb25' }}"
              [animation]="true"
              [animationDuration]="10"
              [showUnits]="false"
              space="10"
              [title]="trilha.subTitulos"
              titleFontSize="20"
              titleColor="#004c6c"
              titleFontWeight="bolder"
              showSubtitle="true"
              [subtitle]="trilha.descricaoTrilhaItem"
              subtitleColor="#004c6c"
              [subtitleFontWeight]="20"
              [subtitleFontSize]="14"
              (click)="trilha.habilitaAcessar ? detalhes(trilha.idTrilhaItem): ''"
              [style]="trilha.habilitaAcessar ? 'cursor: pointer;' : ''">
            </circle-progress>
          </div>
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-12">
        <a href="javascript:history.go(-1)">
          << Voltar</a>
      </div>
    </div>
  </div>

</fieldset>

<button id="btnModal" hidden="true" (click)="instrucaoModal.show()">abri</button>


<div aria-labelledby="dialog-child-name" bsModal #instrucaoModal="bs-modal"
  [config]="{ backdrop: 'static', keyboard: false}" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header header-padding">
        <button aria-label="Close" class="close pull-right" (click)="instrucaoModal.hide(); excluirModal()"
          type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="form-group col-md-12 col-sm-12">
            <label>{{itemModal?.assunto}}</label>
            <div [innerHTML]="itemModal?.descricao" class="mt-3"></div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-md btn-primary btn-secondary"
          (click)="instrucaoModal.hide(); excluirModal()">Fechar</button>
      </div>
    </div>
  </div>
</div>
